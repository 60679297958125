import React from "react";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="This is about page" />
        <link rel="canonical" href="/about" />
      </Helmet>
      About
    </>
  );
};

export default About;
