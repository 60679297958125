import React from "react";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="This is home page" />
        <link rel="canonical" href="/" />
      </Helmet>
      Home
    </>
  );
};

export default Home;
