import React from "react";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="This is contact page" />
        <link rel="canonical" href="/contact" />
      </Helmet>
      Contact
    </>
  );
};

export default Contact;
